import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CreateSessionService {

  protected coreAPIPath = 'https://beta.demo.bluescan.it/beta/docIDRest/rest';

  constructor(protected httpClient: HttpClient) { }

  createSession() {

    let body = {
      "documentTypes": [
          "DRIVER_LICENSE",
          "FISCAL_CODE",
          "IDENTITY_ID",
          "OLD_IDENTITY_ID",
          "VEHICLE_REGISTRATION_DOCUMENT",
          "PERSONE_FISICHE",
          "CERTIFICAZIONE_UNICA",
          "PASSPORT",
          "INVOICE",
          "WHITE_PRESCRIPTION"
      ],
      "resultsCallback": {
          "type": "REST",
          "configurationProperties": [
              {
                  "key": "resultsUrl",
                  "value": "resultsUrl"
                  //"value": "https://easy-i.fs01.vwf.vwfs-ad/SolariTServices/digitalServices/DigitalServices/dataServiceOCR"
              },
              {
                  "key": "resultsMethod",
                  "value": "POST"
              },
              {
                  "key": "pdfUrl",
                  "value": "resultsUrl"
                  //"value": "https://easy-i.fs01.vwf.vwfs-ad/SolariTServices/digitalServices/DigitalServices/digitalDocumentService"
              },
              {
                  "key": "pdfMethod",
                  "value": "POST"
              }
          ]
      }
    }

    return this.httpClient.post(this.coreAPIPath + '/sessions',body);



  }


}
